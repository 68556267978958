<template>
  <v-app>
    <!-- AppBar con Imagen de Fondo -->
    <v-app-bar app color="#022c82" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Transportes y Mudanzas Vivar</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- Espacio entre el título y los elementos a la derecha -->
      <v-btn
        color="#25D366"
        dark
        large
        icon
        class="mr-4"
        href="https://wa.me/+5215520884657"
        target="_blank"
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <v-icon left>mdi-whatsapp</v-icon>
      </v-btn>
      <v-btn
        color="#25D366"
        dark
        large
        class="mr-4"
        href="https://wa.me/+5215520884657"
        target="_blank"
        v-else
      >
        <v-icon left>mdi-whatsapp</v-icon>
        Cotiza por WhatsApp
      </v-btn>
      <v-navigation-drawer
        v-model="drawer"
        absolute
        app
        temporary
        color="#022c82"
      >
        <v-list nav>
          <v-list-item
            v-for="(item, index) in menuItems"
            :key="index"
            @click="() => scrollTo(item.ref)"
            style="cursor: pointer"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-app-bar>
    <v-main class="py-0" color="#f2f2f2">
      <v-container class="pa-0">
        <!-- Secciones Aquí -->
        <section id="inicio">
          <!-- Contenido de la Sección de Inicio -->
          <v-img
            :src="backgroundImage"
            height="90vh"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.15)"
            class="mx-auto"
          >
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col class="text-center" cols="12">
                  <h1 class="display-2 font-weight-bold white--text mb-4">
                    Bienvenidos a Transportes y Mudanzas Vivar
                  </h1>
                  <p class="title white--text">
                    Tu confianza es nuestra prioridad. Mudanzas rápidas y
                    seguras a cualquier parte del país.
                  </p>
                  <v-btn
                    class="mt-5 black--text"
                    color="yellow"
                    dark
                    large
                    @click="scrollTo('contacto')"
                    >Cotizar ahora</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-img>
        </section>
        <section id="servicios" class="section">
          <!-- Contenido de la Sección de Servicios -->
          <v-container class="my-5">
            <v-carousel
              v-if="$vuetify.breakpoint.smAndDown"
              cycle
              show-arrows-on-hover
            >
              <v-carousel-item
                v-for="(service, index) in services"
                :key="index"
                class="text-center"
              >
                <v-img :src="service.image" height="300px"></v-img>
                <h2 class="white--text">{{ service.description }}</h2>
              </v-carousel-item>
            </v-carousel>

            <!-- Grid para escritorio -->
            <v-row v-else align-stretch>
              <v-col cols="12">
                <h2 class="text-center mb-4 white--text">Servicios</h2>
              </v-col>
              <v-col
                cols="12"
                md="3"
                v-for="service in services"
                :key="service.title"
                class="d-flex"
              >
                <v-card class="d-flex flex-column" @click="showModal(service)">
                  <v-img :src="service.image" height="250px"></v-img>
                  <v-card-title class="flex-grow-1 texto">{{
                    service.title
                  }}</v-card-title>
                  <v-card-text>{{ service.description }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
        <section id="contacto" class="section-contacto">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" sm="10" md="8">
                <v-card class="elevation-12" outlined tile>
                  <v-card-title class="headline mx-auto"
                    >¿Listo para el siguiente paso?</v-card-title
                  >
                  <v-card-text>
                    <p class="black--text">
                      Nos comprometemos a ofrecer soluciones personalizadas y
                      eficientes para todas tus necesidades de transportes y
                      mudanzas. Completa el formulario a continuación para
                      obtener una cotización gratuita y sin compromiso. Nuestro
                      equipo de expertos está listo para asistirte y asegurarse
                      de que tu experiencia sea lo más fluida posible.
                    </p>
                    <v-form>
                      <h3 class="headline mb-4">Contáctanos</h3>
                      <v-text-field
                        label="Nombre"
                        prepend-icon="mdi-account"
                        required
                      ></v-text-field>
                      <v-text-field
                        label="Email"
                        prepend-icon="mdi-email"
                        required
                        type="email"
                      ></v-text-field>
                      <v-text-field
                        label="Número de Teléfono"
                        prepend-icon="mdi-phone"
                        required
                        type="tel"
                      ></v-text-field>
                      <v-textarea
                        label="Mensaje"
                        prepend-icon="mdi-pencil"
                        required
                        rows="4"
                      ></v-textarea>
                      <v-btn
                        color="deep-purple accent-4"
                        dark
                        large
                        class="mb-3"
                      >
                        Enviar
                      </v-btn>
                    </v-form>
                    <p class="black--text mt-3">
                      Alternativamente, puedes contactarnos directamente:
                    </p>
                    <v-list-item class="mx-auto ">
                      <v-list-item-icon>
                        <v-icon color="deep-purple accent-4">mdi-phone</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <a :href="'tel:+525520884657'" class="blue--text"
                            >Llámanos ahora al +52 55-2088-4657</a
                          ></v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="green">mdi-whatsapp</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <a
                            href="https://wa.me/+5215520884657"
                            target="_blank"
                            class="green--text"
                            >Envíanos un mensaje en WhatsApp</a
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
        <section id="testimonios" class="section2">
          <!-- Contenido de la Sección de Testimonios -->
          <v-container class="my-5">
            <v-row justify="center">
              <v-col cols="12" sm="12">
                <h2 class="text-center mb-4 white--text">
                  Lo que dicen nuestros clientes
                </h2>
                <v-carousel hide-delimiters height="400">
                  <v-carousel-item
                    v-for="(testimonial, i) in testimonials"
                    :key="i"
                  >
                    <v-img
                      :src="testimonial.image"
                      height="100%"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <v-col class="text-center" cols="12">
                          <p class="display-1 white--text">
                            "{{ testimonial.quote }}"
                          </p>
                          <v-chip class="mt-4">{{ testimonial.author }}</v-chip>
                        </v-col>
                      </v-row>
                    </v-img>
                  </v-carousel-item>
                </v-carousel>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-container>
    </v-main>
    <v-footer color="#022c82">
      <span class="white--text"
        >&copy; 2024 Transportes y Mudanzas Vivar. Todos los derechos
        reservados.</span
      >
    </v-footer>
    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-carousel v-model="selectedServiceIndex" cycle>
          <v-carousel-item v-for="(service, index) in services" :key="index">
            <v-img :src="service.image" class="white--text" height="400px">
              <v-container fill-height fluid>
                <v-layout fill-height>
                  <v-flex xs12 align-end flexbox>
                    <v-card-title>{{ service.title }}</v-card-title>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-img>
            <v-card-text>{{ service.description }}</v-card-text>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    selectedServiceIndex: 0,
    services: [
      {
        title: "Cobertura Nacional en Mudanzas",
        description:
          "Conectamos todos los puntos del país con servicios de mudanza local e interurbana. Tu tranquilidad es nuestra prioridad, llegamos a donde necesites.",
        image: require("@/assets/imagenes/foraneos.jpg"),
        link: "#",
      },
      {
        title: "Soluciones de Mobiliario: Armado y Desarmado",
        description:
          "Deja en nuestras manos el desafío de tus muebles. Nos encargamos del armado y desarmado para una mudanza sin estrés.",
        image: require("@/assets/imagenes/armadoMuebles.jpg"),
        link: "#",
      },
      {
        title: "Protección Total: Servicio de Emplayado",
        description:
          "Aseguramos la integridad de tus pertenencias con nuestro completo servicio de emplayado. Más que mover, protegemos tus bienes.",
        image: require("@/assets/imagenes/emplayado-muebles.jpg"),
        link: "#",
      },
      {
        title: "Almacenamiento Seguro: Contenedores para Rentar",
        description:
          "Maximiza el espacio y protege tus bienes con nuestras opciones de contenedores. Seguridad y comodidad en cada paso de tu mudanza.",
        image: require("@/assets/imagenes/contenedores.jpg"),
        link: "#",
      },
      {
        title: "Equipo Profesional para Mudanzas Excepcionales",
        description:
          "Confía en nuestro equipo altamente capacitado para manejar cada aspecto de tu mudanza con la máxima eficiencia y cuidado.",
        image: require("@/assets/imagenes/mejorEquipo.jpg"),
        link: "#",
      },
      {
        title: "Servicio Especializado en Volado de Muebles",
        description:
          "Ningún espacio es inaccesible para nosotros. Ofrecemos soluciones de volado para garantizar que tus muebles lleguen intactos a cualquier altura.",
        image: require("@/assets/imagenes/volado.jpg"),
        link: "#",
      },
      {
        title: "Cuidado Excepcional para Artículos Frágiles",
        description:
          "Tratamos cada artículo con delicadeza singular. Servicios especializados para asegurar la protección total de tus objetos más frágiles.",
        image: require("@/assets/imagenes/delicadeza.jpg"),
        link: "#",
      },
      {
        title: "Flota Versátil para Cada Necesidad de Mudanza",
        description:
          "Desde pequeñas vanes hasta grandes camiones, tenemos el vehículo perfecto para cada tamaño y tipo de mudanza.",
        image: require("@/assets/imagenes/vehiculos.jpg"),
        link: "#",
      },
      // Agrega más servicios con sus respectivas imágenes y enlaces
    ],
    testimonials: [
      {
        quote: "El servicio fue excepcional desde el inicio hasta el fin.",
        author: "Cliente Satisfecho",
        image: "https://example.com/testimonial-image-url.jpg",
      },
      {
        quote: "Mudanza rápida y sin contratiempos, ¡muy recomendado!",
        author: "Familia Rodríguez",
        image: "https://example.com/testimonial-image-url2.jpg",
      },
      // Agrega más testimonios con sus respectivas imágenes
    ],
    drawer: false,
    menuItems: [
      { title: "Inicio", ref: "inicio" },
      { title: "Servicios", ref: "servicios" },
      { title: "Testimonios", ref: "testimonios" },
      { title: "Contacto", ref: "contacto" },
    ],
  }),
  methods: {
    showModal(service) {
      this.selectedServiceIndex = this.services.findIndex(
        (s) => s.title === service.title
      );
      this.dialog = true;
    },
    scrollTo(ref) {
      const element = document.getElementById(ref);
      const yOffset = -64;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
      this.drawer = false;
    },
  },
  computed: {
    backgroundImage() {
      return this.$vuetify.breakpoint.smAndDown
        ? require("@/assets/portadaVivar.jpg") // Imagen para móviles
        : require("@/assets/portadaVivarDesktop.jpg"); // Imagen predeterminada para escritorio
    },
  },
};
</script>

<style scoped>
.texto {
  word-break: break-word;
}
.section {
  background: linear-gradient(to bottom, #022c82, #2b4c9e, #5071bf);
}
.section2 {
  background: linear-gradient(to bottom, #022c82 0%, #a1c4fd 100%);
}
.section-contacto {
  background: linear-gradient(to bottom, #022c82, #035096, #047ab3);
  padding: 20px 0; /* Espaciado vertical */
}

.elevation-12 {
  border-radius: 8px; /* Bordes redondeados para la tarjeta */
}

@media (max-width: 600px) {
  .section-contacto {
    padding: 15px; /* Ajustar el espaciado en dispositivos móviles */
  }
}
</style>
