<!-- eslint-disable prettier/prettier -->
<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="8" md="6">
            <v-card class="elevation-12" outlined tile>
              <v-card-title class="headline"
                >Valoración del Servicio</v-card-title
              >
              <v-card-text>
                <p>
                  Agradecemos tus comentarios y sugerencias. Tu opinión es muy
                  importante para nosotros.
                </p>
                <v-form>
                  <v-text-field label="Nombre" required></v-text-field>
                  <v-text-field
                    label="Email"
                    required
                    type="email"
                  ></v-text-field>
                  <v-rating
                    v-model="rating"
                    dense
                    color="amber"
                    empty-icon="$vuetify.icons.ratingEmpty"
                    half-increments
                    hover
                  ></v-rating>
                  <v-textarea label="Comentarios" required></v-textarea>
                  <v-btn color="deep-purple accent-4" dark large class="mb-3"
                    >Enviar Valoración</v-btn
                  >
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Review",
  data() {
    return {
      rating: 0, // Valor inicial de la valoración
      // Otras propiedades de data...
    };
  },
};
</script>

<style>
/* Añade aquí tus estilos CSS, puedes usar los mismos que en tu otra página para mantener la consistencia */
</style>
